import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { getSiteUrl } from 'helpers/routingHelper';

type PaymentBackProps = {
  location: Location;
};

const PaymentBack: React.FC<PaymentBackProps> = ({ location }) => {
  const params = parse(location.search);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'back',
      },
      getSiteUrl() || ''
    );
  }, [params]);

  return null;
};

export default PaymentBack;
